import Grid from "@mui/material/Grid";
import { useState } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { Box, TextField, Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function MyUser({token}) {
  const [status, setStatus] = useState("INITIAL");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus("LOADING");
    const data = new FormData(event.currentTarget);
    try {
      const oldpw = data.get("oldpassword");
      const newpw = data.get("newpassword");
      const checkpw = data.get("checkpassword");

      if (checkpw !== newpw){
        setStatus("ERROR");
        setError("Die eingegebenen Passwörter stimmen nicht überein.");
        return;
      }

      const resp = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/createPassword",
        {
          oldpw, newpw
        },{
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setStatus("SUCCESS");
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
      setError("Passwort konnte nicht geändert werden, da entweder keine Verbindung besteht oder das aktuelle Passwort nicht korrekt ist.")
      console.error(err);
    }
  };
  return (
    <Grid container spacing={3}>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            {status === "ERROR" ? (
              <Alert severity="error">{error}</Alert>
            ) : status === "SUCCESS" ? (
              <Alert severity="success">Passwort erfolgreich geändert.</Alert>
            ) : null}
            <TextField
              margin="normal"
              required
              fullWidth
              name="oldpassword"
              label="Aktuelles Passwort"
              type="password"
              id="oldpassword"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newpassword"
              label="Neues Passwort"
              type="password"
              id="newpassword"
              autoComplete="new-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="checkpassword"
              label="Neues Passwort wiederholen"
              type="password"
              id="checkpassword"
              autoComplete="new-password"
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={status === "LOADING"}
              sx={{ mt: 3, mb: 2 }}
            >
              Passwort ändern
            </LoadingButton>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
