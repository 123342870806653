import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import AddIcon from '@mui/icons-material/Add';
import TableCell from "@mui/material/TableCell";
import { Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import axios from "axios";
import MyBookingRow from "./MyBookingRow";
import { Switch, FormControlLabel } from "@mui/material";
import moment from "moment";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import React from "react";

export default function MyBookings({ token, settings }) {
  const [status, setStatus] = useState("NONE");
  const [data, setData] = useState([]);
  const [showHistory, setShowHistory] = useState(false);

  const getData = async () => {
    setStatus("LOADING");
    try {
      const resp = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/getBookings",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setData(
        resp.data.sort((a, b) => {
          if (a.start === b.start) return 0;
          return a.start > b.start ? 1 : -1;
        })
      );
      setStatus("READY");
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
      console.error(err);
    }
  };

  const deleteOrder = async (id) => {
    if (!window.confirm("Möchtest du die Buchung wirklich löschen?"))
      return false;
    try {
      const resp = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/deleteBooking?id=" + id,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      getData();
    } catch (err) {
      // Handle Error Here
      window.alert("Fehler beim Löschen der Buchung. Bitte erneut versuchen.");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const rows = data.filter((row) => {
    if (!showHistory && row.end < moment().format("YYYY-MM-DD")) return false;

    return true;
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box sx={{ display: "flex", padding: 1 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showHistory}
                  onChange={(event) => setShowHistory(event.target.checked)}
                />
              }
              label="Zeige vergangene Buchungen"
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {status === "LOADING" ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : status === "ERROR" ? (
          <Stack spacing={2}>
            <div>Verbindungsfehler.</div>
            <Button variant="contained" onClick={getData}>
              Erneut versuchen
            </Button>
          </Stack>
        ) : rows.length === 0 ? (
          <Paper sx={{padding: 2}}>
            <Box sx={{mb: 2}}>
              Ich habe keine Buchungen gefunden, die den aktuellen Filtereinstellungen
              entsprechen.
            </Box>
            <Button startIcon={<AddIcon />} component={Link} to="/new" variant="contained" onClick={getData}>
               Neue Buchungsanfrage
            </Button>
          </Paper>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Anreise</TableCell>
                  <TableCell>Abreise</TableCell>
                  <TableCell align="right">Intern</TableCell>
                  <TableCell align="right">Extern</TableCell>
                  <TableCell align="right">Preis</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <MyBookingRow
                    settings={settings}
                    row={row}
                    deleteOrder={deleteOrder}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
}
