import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Switch, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import React from "react";
import AllBookingsRow from "./AllBookingsRow";

const statusSorting = {
  NEW: 1,
  EDITED: 0,
  ACCEPTED: 2,
  DENIED: 3,
};

export default function AllBookings({ token, settings }) {
  const [status, setStatus] = useState("NONE");
  const [data, setData] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [showSupervised, setShowSupervised] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [modalComment, setModalComment] = useState("");
  const [modalId, setModalId] = useState(false);

  const getData = async () => {
    setStatus("LOADING");
    try {
      const resp = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/getAllBookings",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setData(
        resp.data.sort((a, b) => {
          if (statusSorting[a.status] === statusSorting[b.status]) {
            if (a.start === b.start) return 0;
            return a.start < b.start ? -1 : 1;
          }
          return statusSorting[a.start] < statusSorting[b.start] ? -1 : 1;
        })
      );
      setStatus("READY");
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
      console.error(err);
    }
  };

  const commentModal = (id, accept, deny) => {
    setModalId(id);
    setModalState((accept ? 1 : 0) + (deny ? 2 : 0));
    setModalComment("");
  };

  const closeModal = () => {
    setModalId(false);
    setModalState(false);
    setModalComment();
  };

  const deleteOrder = async (id) => {
    if (!window.confirm("Möchtest du die Buchung wirklich löschen?"))
      return false;
    try {
      const resp = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/deleteBooking?id=" + id,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      getData();
    } catch (err) {
      // Handle Error Here
      window.alert("Fehler beim Löschen der Buchung. Bitte erneut versuchen.");
    }
  };

  const uploadState = async (id, state, comment) => {
    closeModal();
    setStatus("LOADING");
    try {
      const resp = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/createStatus",
        { id, status: state, comment },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      getData();
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
      console.error(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const rows = data.filter((row) => {
    if (!showHistory && row.end < moment().format("YYYY-MM-DD")) return false;
    if (!showSupervised && (row.state === "ACCEPTED" || row.state === "DENIED"))
      return false;
    return true;
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box sx={{ display: "flex", padding: 1 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showHistory}
                  onChange={(event) => setShowHistory(event.target.checked)}
                />
              }
              label="Zeige vergangene Buchungen"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showSupervised}
                  onChange={(event) => setShowSupervised(event.target.checked)}
                />
              }
              label="Zeige geprüfte Buchungen"
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {status === "LOADING" ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : status === "ERROR" ? (
          <Stack spacing={2}>
            <div>Verbindungsfehler.</div>
            <Button variant="contained" onClick={getData}>
              Erneut versuchen
            </Button>
          </Stack>
        ) : rows.length === 0 ? (
          <Paper>
            <Box p={2}>
              Keine Buchungen gefunden, die den aktuellen Filtereinstellungen
              entsprechen.
            </Box>
          </Paper>
        ) : (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Gast</TableCell>
                    <TableCell>Anreise</TableCell>
                    <TableCell>Abreise</TableCell>
                    <TableCell align="right">Intern</TableCell>
                    <TableCell align="right">Extern</TableCell>
                    <TableCell align="right">Preis</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <AllBookingsRow
                      row={row}
                      settings={settings}
                      deleteOrder={deleteOrder}
                      uploadState={uploadState}
                      commentModal={commentModal}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Modal
              open={modalId ? true : false}
              onClose={closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Paper
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  padding: 20,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <TextField
                  margin="dense"
                  label="Kommentar"
                  fullWidth
                  onChange={(event) => setModalComment(event.target.value)}
                  value={modalComment}
                  multiline
                />
                <Stack direction="row">
                  {modalState === 1 || modalState === 3 ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color={"primary"}
                      onClick={() =>
                        uploadState(modalId, "ACCEPTED", modalComment)
                      }
                    >
                      <CheckIcon />
                    </Button>
                  ) : null}{" "}
                  {modalState === 2 || modalState === 3 ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color={"secondary"}
                      onClick={() =>
                        uploadState(modalId, "DENIED", modalComment)
                      }
                    >
                      <DoDisturbIcon />
                    </Button>
                  ) : null}
                </Stack>
              </Paper>
            </Modal>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}
