import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function History({ open, history }) {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 1 }}>
        <Typography variant="h6" gutterBottom component="div">
          Buchungshistorie
        </Typography>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Person</TableCell>
              <TableCell>Feld</TableCell>
              <TableCell>Wert alt</TableCell>
              <TableCell>Wert neu</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((change, i) =>
              change.changes.map((one, j) => (
                <TableRow key={i + "-" + j}>
                  <TableCell>{j === 0 ? change.timestamp : ""}</TableCell>
                  <TableCell>{j === 0 ? change.user : ""}</TableCell>
                  <TableCell>{one[0]}</TableCell>
                  {one.length === 1 ? (
                    <TableCell>{one[1]}</TableCell>
                  ) : (
                    <React.Fragment>
                      <TableCell>{one[1]}</TableCell>
                      <TableCell>{one[2]}</TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  );
}
