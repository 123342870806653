import LoginForm from "./components/LoginForm";
import ResetForm from "./components/ResetForm";
import RenewPasswordForm from "./components/RenewPasswordForm";
import LoginWrapper from "./components/LoginWrapper";
import MainPage from "./components/MainPage";
import MyBookings from "./components/MyBookings";
import Calendar from "./components/Calendar";
import NewBooking from "./components/NewBooking";
import AllBookings from "./components/AllBookings";
import AllUsers from "./components/AllUsers";
import GlobalSettings from "./components/GlobalSettings";
import MyUser from "./components/MyUser";
import UserEdit from "./components/UserEdit";
import moment from "moment";
import "moment/locale/de";
import { TitleWrapper, TitleProvider } from "./components/Title";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "react-dates/initialize";
import { red, yellow } from "@mui/material/colors";
import { useState, Fragment } from "react";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#bc0906",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#f4c92e",
    },
    black: {
      main: "#000",
      contrastText: "#fff",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

moment.locale("de");

function App() {
  const [token, setToken] = useState(false);
  const [username, setUsername] = useState(false);
  const [settings, setSettings] = useState({});

  const onSuccess = async (username, token, settings) => {
    setSettings(settings);
    setToken(token);
    setUsername(username);
  };

  const logout = () => {
    setToken(false);
    setUsername(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <TitleProvider>
        <BrowserRouter>
          <Routes>
            {username ? (
              <Route
                path="/"
                element={
                  <MainPage
                    token={token}
                    settings={settings}
                    username={username}
                    logout={logout}
                  />
                }
              >
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={settings.isAdmin ? "/all" : "/my"}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <Navigate
                      replace
                      to={settings.isAdmin ? "/all" : "/my"}
                    />
                  }
                />
                <Route
                  path="my"
                  element={
                    <TitleWrapper title="Meine Buchungen">
                      <MyBookings token={token} settings={settings} />
                    </TitleWrapper>
                  }
                />
                <Route
                  path="calendar"
                  element={
                    <TitleWrapper title="Kalender">
                      <Calendar token={token} settings={settings} />
                    </TitleWrapper>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <TitleWrapper title="Globale Einstellungen">
                      <GlobalSettings settings={settings} setSettings={setSettings} token={token} />
                    </TitleWrapper>
                  }
                />
                <Route
                  path="new"
                  element={
                    <TitleWrapper title="Neue Buchung">
                      <NewBooking
                        edit={false}
                        settings={settings}
                        token={token}
                      />
                    </TitleWrapper>
                  }
                  name="Neue Buchung"
                />
                {settings.isAdmin ? (
                  <Route
                    path="editUser/:userId"
                    element={
                      <TitleWrapper title="Benutzer bearbeiten">
                        <UserEdit edit={true} settings={settings} token={token} />
                      </TitleWrapper>
                    }
                  />
                ) : null}
                {settings.isAdmin ? (
                  <Route
                    path="addUser"
                    element={
                      <TitleWrapper title="Benutzer erstellen">
                        <UserEdit edit={false} settings={settings} token={token} />
                      </TitleWrapper>
                    }
                  />
                ) : null}
                {settings.isAdmin ? (
                  <Route
                    path="all"
                    element={
                      <TitleWrapper title="Buchungsübersicht">
                        <AllBookings settings={settings} token={token} />
                      </TitleWrapper>
                    }
                  />
                ) : null}
                {settings.isAdmin ? (
                  <Route
                    path="users"
                    element={
                      <TitleWrapper title="Benutzerverwaltung">
                        <AllUsers settings={settings} token={token} />
                      </TitleWrapper>
                    }
                  />
                ) : null}
                <Route
                  path="edit/:bookingId"
                  element={
                    <TitleWrapper title="Buchung bearbeiten">
                      <NewBooking
                        settings={settings}
                        edit={true}
                        token={token}
                      />
                    </TitleWrapper>
                  }
                  name="Buchung bearbeiten"
                />
                <Route
                  path="myuser"
                  element={
                    <TitleWrapper title="Mein Benutzer">
                      <MyUser token={token} />
                    </TitleWrapper>
                  }
                  name="Mein Benutzer"
                />
              </Route>
            ) : (
              <Route
                path="/"
                element={<LoginWrapper />}
              >
                <Route
                  index
                  element={<Navigate replace to="/login" />}
                />
                <Route
                  path="*"
                  element={<Navigate replace to="/login" />}
                />
                <Route
                  path="login"
                  element={<LoginForm onSuccess={onSuccess} />}
                />
                
                <Route
                  path="resetPassword"
                  element={<ResetForm />}
                />
                <Route
                  path="renewPassword/:token"
                  element={<RenewPasswordForm />}
                />
              </Route>
            )}
          </Routes>
        </BrowserRouter>
      </TitleProvider>
    </ThemeProvider>
  );
}

export default App;
