import * as React from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { Link, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import Paper from "@mui/material/Paper";
import huette from "../images/huette.jpg";

export default function SignIn({ onSuccess }) {
  const [status, setStatus] = React.useState("INITIAL");
  const location = useLocation();

  let fromReset = false;
  if (location.state) {
    fromReset = true;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus("LOADING");
    const data = new FormData(event.currentTarget);
    try {
      const username = data.get("email");
      const resp = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/login_check",
        {
          email: username,
          password: data.get("password"),
        }
      );
      const token = resp.data.token;
      const resp2 = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/getSettings",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const settings = resp2.data;
      onSuccess(username, token, settings);
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
      console.error(err);
    }
  };

  return (
    <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Interne Anmeldung
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            {status === "ERROR" ? (
              <Alert severity="error">Fehler bei der Anmeldung.</Alert>
            ) : null}
            {fromReset && status === "INITIAL" ? (
              <Alert severity="success">Passwort wurde erfolgreich geändert.</Alert>
            ) : null}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email-Adresse"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={status === "LOADING"}
              sx={{ mt: 3, mb: 2 }}
            >
              Anmelden
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link
                  to="/resetPassword"
                  variant="body2"
                >
                  Passwort vergessen?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
  );
}
