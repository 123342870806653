import Grid from "@mui/material/Grid";
import { useState } from "react";
import axios from "axios";
import { useLocation, Navigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import {
  Box,
  TextField,
  Alert,
  FormControlLabel,
  Switch,
  FormGroup,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function UserEdit({ token, edit }) {
  const location = useLocation();

  if (!location.state || !("row" in location.state)) {
    edit = false;
  }

  const initial = edit ? location.state.row : {};

  const [status, setStatus] = useState("INITIAL");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus("LOADING");
    const data = new FormData(event.currentTarget);
    try {
      const email = edit ? initial.email : data.get("email");
      const name = data.get("name");
      const isAdmin = data.has("isAdmin");
      const resetPassword = data.has("resetPassword");

      const resp = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/createUser",
        {
          email,
          name,
          isAdmin,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (resetPassword) {
        await axios.post(process.env.REACT_APP_SERVER_URL + "/bpRequestEmail", {
          email,
        });
      }
      setStatus("SUCCESS");
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
      console.error(err);
    }
  };
  return (
    <Grid container spacing={3}>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            {status === "ERROR" ? (
              <Alert severity="error">Fehler beim Speichern.</Alert>
            ) : status === "SUCCESS" ? (
                <Navigate
                replace to="/users" />
            ) : null}
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              label="E-Mail"
              type="text"
              defaultValue={"email" in initial ? initial.email : ""}
              disabled={edit}
              id="email"
              autoComplete="none"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="name"
              label="Name"
              defaultValue={"name" in initial ? initial.name : ""}
              type="text"
              id="name"
              autoComplete="none"
            />
            <FormGroup>
              <FormControlLabel
                style={{ marginBottom: 10 }}
                margin="normal"
                fullWidth
                control={
                  <Switch
                    defaultChecked={
                      "admin" in initial
                        ? initial.admin
                          ? true
                          : false
                        : false
                    }
                    name="isAdmin"
                    id="isAdmin"
                  />
                }
                label="Admin"
              />
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={!edit}
                    name="resetPassword"
                    id="resetPassword"
                  />
                }
                label="Passwort-Link versenden"
              />
            </FormGroup>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={status === "LOADING"}
              sx={{ mt: 3, mb: 2 }}
            >
              Speichern
            </LoadingButton>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
