import {createContext,useContext,useEffect, useState} from "react";

export const TitleContext = createContext({
    title: "",
    setTitle: () => {}
  });
  
  export const useTitle = () => useContext(TitleContext);
  
 export const TitleProvider = ({ children }) => {
    const [title, setTitle] = useState("");
    return (
      <TitleContext.Provider value={{ title, setTitle }}>
        {children}
      </TitleContext.Provider>
    );
  };
  
  export const TitleWrapper = ({ children, title }) => {
    const { setTitle } = useTitle();
  
    useEffect(() => {
      setTitle(title);
    }, [setTitle, title]);
  
    return children;
  };