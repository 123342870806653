import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Switch, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import React from "react";
import AllUsersRow from "./AllUsersRow";


export default function AllUsers({ token, settings }) {
  const [status, setStatus] = useState("NONE");
  const [data, setData] = useState([]);

  const getData = async () => {
    setStatus("LOADING");
    try {
      const resp = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/getUsers",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setData(
        resp.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
      );
      setStatus("READY");
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
      console.error(err);
    }
  };

  const deleteUser = async (id) => {
    if (!window.confirm("Möchtest du den Benutzer wirklich löschen?"))
      return false;
    try {
      const resp = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/deleteUser?id=" + id,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      getData();
    } catch (err) {
      // Handle Error Here
      window.alert("Fehler beim Löschen des Benutzers. Bitte erneut versuchen.");
    }
  };


  useEffect(() => {
    getData();
  }, []);

  const rows = data;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box sx={{ display: "flex", padding: 1 }}>
          <Button
                variant="contained"
                component={Link}
                to={"/addUser"}
            
              >
                <AddIcon />
              </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {status === "LOADING" ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : status === "ERROR" ? (
          <Stack spacing={2}>
            <div>Verbindungsfehler.</div>
            <Button variant="contained" onClick={getData}>
              Erneut versuchen
            </Button>
          </Stack>
        ) : rows.length === 0 ? (
          <Paper>
            <Box p={2}>
              Keine Benutzer gefunden, die den aktuellen Filtereinstellungen
              entsprechen.
            </Box>
          </Paper>
        ) : (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                  <TableCell>Name</TableCell>
                    <TableCell>E-Mail</TableCell>
                    
                    <TableCell>Admin</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <AllUsersRow
                      row={row}
                      settings={settings}
                      deleteUser={deleteUser}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}
