import Grid from "@mui/material/Grid";
import { useState } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { Box, TextField, Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function MyUser({token,settings,setSettings}) {
  const [status, setStatus] = useState("INITIAL");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus("LOADING");
    const data = new FormData(event.currentTarget);
    try {
      const internalPrice = data.get("internalPrice");
      const externalPrice = data.get("externalPrice");

      const resp = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/createSettings",
        {
          internalPrice, externalPrice
        },{
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setSettings(resp.data);
      setStatus("SUCCESS");
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
      setError("Fehler beim Speichern der Änderungen.")
      console.error(err);
    }
  };
  return (
    <Grid container spacing={3}>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            {status === "ERROR" ? (
              <Alert severity="error">{error}</Alert>
            ) : status === "SUCCESS" ? (
              <Alert severity="success">Einstellungen erfolgreich geändert.</Alert>
            ) : null}
            <TextField
              margin="normal"
              required
              fullWidth
              name="internalPrice"
              label="Interner Preis"
              type="number"
              id="internalPrice"
              defaultValue={settings.internalPrice}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="externalPrice"
              label="externer Preis"
              type="number"
              id="externalPrice"
              defaultValue={settings.externalPrice}
            />
            
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={status === "LOADING"}
              sx={{ mt: 3, mb: 2 }}
            >
              Einstellungen ändern
            </LoadingButton>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
