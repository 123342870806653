import Grid from "@mui/material/Grid";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import ArrowBackIosTwoToneIcon from "@mui/icons-material/ArrowBackIosTwoTone";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const weekdays = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

const months = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

export default function Calendar({ token, settings }) {
  const [status, setStatus] = useState("NONE");
  const [data, setData] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());

  const getData = async () => {
    setStatus("LOADING");
    try {
      const resp = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          "/getCalendar/" +
          year +
          "-" +
          (month + 1),
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const startOfMonthDate = year + "-" + (month + 1) + "-01";

      const startOfMonth = moment(startOfMonthDate);
      const calendarStart = moment(startOfMonth).startOf("week");
      const endOfMonth = moment(startOfMonthDate).endOf("month");
      const calendarEnd = moment(endOfMonth).endOf("week");
      const xda = {};
      for (let booking of resp.data) {
        const bookingEnd = moment(booking.end);
        for (
          let now = moment(booking.start);
          now <= bookingEnd;
          now = moment(now).add(1, "day")
        ) {
          const key = now.format("YYYY-MM-DD");
          if (!(key in xda)) {
            xda[key] = [];
          }
          xda[key].push({
            name: booking.name,
            type: booking.start === key ? 2 : booking.end === key ? 0 : 1,
          });
        }
      }

      const days = [];
      for (
        let now = moment(calendarStart);
        now <= calendarEnd;
        now = moment(now).add(1, "day")
      ) {
        const key = now.format("YYYY-MM-DD");
        days.push({
          date: moment(now),
          bookings: key in xda ? xda[key].sort((a, b) => a.type - b.type) : [],
        });
      }

      setData(days);
      setStatus("READY");
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
      console.error(err);
    }
  };

  const changeMonth = (diff: number) => {
    if (month + diff < 0) {
      setYear(year - 1);
      setMonth(12 + month + diff);
    } else if (month + diff > 11) {
      setYear(year + 1);
      setMonth(month + diff - 12);
    } else {
      setMonth(month + diff);
    }
  };

  useEffect(() => {
    getData();
  }, [year, month]);

  console.log(data);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ display: "flex", columnGap: "10px" }}>
          <IconButton onClick={() => changeMonth(-1)}>
            <ArrowBackIosTwoToneIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              minWidth: "145px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">
              {months[month]} {year}
            </Typography>
          </Box>
          <IconButton onClick={() => changeMonth(1)}>
            <ArrowForwardIosTwoToneIcon />
          </IconButton>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {status === "LOADING" ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : status === "ERROR" ? (
          <Stack spacing={2}>
            <div>Verbindungsfehler.</div>
            <Button variant="contained" onClick={getData}>
              Erneut versuchen
            </Button>
          </Stack>
        ) : (
          <Paper
            sx={{
              padding: "7px",
              background: "white",
              display: "grid",
              gridTemplateColumns: "repeat(7,auto)",
              gap: "5px",
            }}
          >
            {weekdays.map((x) => (
              <Box
                sx={{
                  padding: "5px",
                  background: "#444",
                  color: "white",
                  boxShadow: " 1px 1px 3px rgba(0,0,0,0.2)",
                }}
              >
                {x}
              </Box>
            ))}
            {data.map((x) => (
              <Box sx={{ background: "#f9f9f9",
              padding: "5px",
              boxShadow: " 1px 1px 3px rgba(0,0,0,0.4)", }}>
                <Box sx={{color: "#777", textAlign: "right"}}>{x.date.format("DD")}</Box>
                <Box sx={{display: "flex", flexDirection: "column"}}>{x.bookings.map((b) => <Box>{b.name}</Box>)}</Box>
              </Box>
            ))}
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
