import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useLocation, Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import "react-dates/lib/css/_datepicker.css";
import StepLabel from "@mui/material/StepLabel";
import Table from "@mui/material/Table";
import ClearIcon from "@mui/icons-material/Clear";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { START_DATE } from "react-dates/constants";
import { DayPickerRangeController, isInclusivelyAfterDay } from "react-dates";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import moment from "moment";

export default function NewBooking({ token, edit, settings }) {
  const steps = [
    "Zeitraum wählen",
    "Belegung erfassen",
    edit ? "Änderung absenden" : "Buchunganfrage absenden",
  ];
  const location = useLocation();

  if (!location.state || !("row" in location.state)) {
    edit = false;
  }

  const initial = edit ? location.state.row : {};

  console.log(initial);

  const [activeStep, setActiveStep] = React.useState(0);

  const [userStatus, setUserStatus] = React.useState("NONE");
  const [userData, setUserData] = React.useState({});

  const [status, setStatus] = React.useState("LOADING");
  const [sendStatus, setSendStatus] = React.useState("LOADING");
  const [data, setData] = React.useState([]);

  const [startDate, setStartDate] = React.useState(
    edit ? moment(initial.start) : null
  );
  const [endDate, setEndDate] = React.useState(
    edit ? moment(initial.end) : null
  );
  const [focusedInput, setFocusedInput] = React.useState(START_DATE);

  const [internalGuests, setInternalGuests] = React.useState(
    edit ? initial.internalGuests : null
  );
  const [user, setUser] = React.useState(edit ? initial.userId : 0);

  const [externalGuests, setExternalGuests] = React.useState(
    edit ? initial.externalGuests : null
  );
  const [comment, setComment] = React.useState(edit ? initial.comment : null);

  const getData = async () => {
    setStatus("LOADING");
    try {
      const resp = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/getBlocked/"+(!!initial.id ? initial.id : 0),
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setData(resp.data);
      setStatus("READY");
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
      console.error(err);
    }
  };

  const getUserList = async () => {
    if (!settings.isAdmin) {
      setUserStatus("READY");
      return;
    }
    setUserStatus("LOADING");
    try {
      const resp = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/getUsers",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setUserData(Object.fromEntries(resp.data.map((x) => [x.id, x]).concat([[0,{name: "\xa0mich\xa0"}]])));
      setUserStatus("READY");
    } catch (err) {
      // Handle Error Here
      setUserStatus("ERROR");
      console.error(err);
    }
  };

  const send = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSendStatus("LOADING");
    try {
      const resp = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/createOrder",
        {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
          internalGuests: internalGuests || 1,
          externalGuests: externalGuests || 0,
          comment: comment || "",
          id: edit ? initial.id : false,
          user,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setSendStatus("DONE");
    } catch (err) {
      // Handle Error Here
      setSendStatus("ERROR");
      console.error(err);
    }
  };

  React.useEffect(() => {
    getData();
    getUserList();
  }, []);

  const setDates = (s, e) => {
    setStartDate(s);
    setEndDate(e);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isOutsideRange = (day) => {
    if (!isInclusivelyAfterDay(day, moment())) return true;
    if (focusedInput === START_DATE) return false;
    const sd = startDate.format("YYYY-MM-DD");
    let nextStart = null;
    for (let b of data) {
      console.log(b, sd);
      if (b.start < sd) continue;
      nextStart = b.start;
      break;
    }
    if (!nextStart) return false;
    return day.format("YYYY-MM-DD") > nextStart;
  };

  const isDayBlocked = (dayObj) => {
    const day = dayObj.format("YYYY-MM-DD");

    for (let b of data) {
      if (focusedInput === START_DATE && day >= b.start && day < b.end)
        return true;
      if (focusedInput !== START_DATE && day > b.start && day <= b.end)
        return true;
    }
    return false;
  };

  const getTitle = (dayObj) => {
    const day = dayObj.format("YYYY-MM-DD");

    for (let b of data) {
      if (focusedInput === START_DATE && day >= b.start && day < b.end)
        return b.name;
      if (focusedInput !== START_DATE && day > b.start && day <= b.end)
        return b.name;
    }
    return false;
  };

  console.log(userData, userStatus);

  return (
    <Grid container spacing={3}>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {status === "LOADING" || userStatus === "LOADING" ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : status === "ERROR" || userStatus === "ERROR" ? (
            <Stack spacing={2}>
              <div>Verbindungsfehler.</div>
              <Button variant="contained" onClick={getData}>
                Erneut versuchen
              </Button>
            </Stack>
          ) : (
            <React.Fragment>
              <Stepper color="secondary" activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step color="secondary" key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {activeStep === 0 ? (
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  {settings.isAdmin ? (
                    <div>
                      <Autocomplete
                        sx={{ mt: "8px", mb: "4px" }}
                        value={user}
                        onChange={(_event, newValue) => {
                          setUser(newValue);
                        }}
                        fullWidth
                        options={Object.keys(userData).sort((x, y) =>
                          userData[x].name.localeCompare(userData[y].name)
                        )}
                        getOptionLabel={(owner) => userData[owner].name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Buchung für"
                            placeholder="mich"
                          />
                        )}
                      />
                    </div>
                  ) : null}
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <TextField
                      style={{ flex: 1 }}
                      InputProps={{
                        readOnly: true,
                      }}
                      label="Anreise"
                      InputLabelProps={{ shrink: true }}
                      value={
                        startDate
                          ? startDate.format("dd., DD. MMM YYYY")
                          : "Bitte wählen"
                      }
                    />{" "}
                    <ArrowForwardIcon />
                    <TextField
                      style={{ flex: 1 }}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={
                        endDate
                          ? endDate.format("dd., DD. MMM YYYY")
                          : "Bitte wählen"
                      }
                      label="Abreise"
                    />
                    <Button
                      variant="contained"
                      className="fullHeighted"
                      onClick={() => {
                        setStartDate(null);
                        setEndDate(null);
                        setFocusedInput(START_DATE);
                      }}
                      startIcon={<ClearIcon />}
                    >
                      Zurücksetzen
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <DayPickerRangeController
                      style={{ flex: 1 }}
                      renderDayContents={(day) => {
                        return (
                          <div
                            className="searchDay"
                            title={getTitle(day)}

                            // onClick={() => day.onDayClick(day.day)}
                          >
                            {moment(day).format("D")}
                          </div>
                        );
                      }}
                      startDate={startDate} // momentPropTypes.momentObj or null,
                      endDate={endDate} // momentPropTypes.momentObj or null,
                      numberOfMonths={
                        window.innerWidth > 1200
                          ? 3
                          : window.innerWidth > 910
                          ? 2
                          : 1
                      }
                      onDatesChange={({ startDate, endDate }) =>
                        setDates(startDate, endDate)
                      } // PropTypes.func.isRequired,
                      focusedInput={focusedInput || START_DATE} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      isOutsideRange={isOutsideRange}
                      isDayBlocked={isDayBlocked}
                      onFocusChange={(newFocusedInput) => {
                        setFocusedInput(newFocusedInput);
                      }}
                    />
                  </div>
                </Box>
              ) : activeStep === 1 ? (
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div style={{ marginTop: 10 }}>
                    <TextField
                      label="Anzahl interner Übernachtungsgäste"
                      variant="outlined"
                      onChange={(event) =>
                        setInternalGuests(event.target.value)
                      }
                      value={internalGuests}
                      type="number"
                      fullWidth
                    />
                    <TextField
                      label="Anzahl externer Übernachtungsgäste"
                      variant="outlined"
                      type="number"
                      onChange={(event) =>
                        setExternalGuests(event.target.value)
                      }
                      value={externalGuests}
                      fullWidth
                    />
                    <TextField
                      label="Kommentare und sonstige Anmerkungen"
                      fullWidth
                      onChange={(event) => setComment(event.target.value)}
                      value={comment}
                      multiline
                    />
                  </div>
                </Box>
              ) : activeStep === 2 ? (
                <div style={{ marginTop: 10 }}>
                  <TableContainer component={Box}>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Start
                          </TableCell>
                          <TableCell align="right">
                            {startDate
                              ? startDate.format("dd., DD. MMM YYYY")
                              : "Fehlt!"}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Ende
                          </TableCell>
                          <TableCell align="right">
                            {endDate
                              ? endDate.format("dd., DD. MMM YYYY")
                              : "Fehlt!"}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Nächte
                          </TableCell>
                          <TableCell align="right">
                            {startDate && endDate
                              ? endDate.diff(startDate, "DAY")
                              : "Fehlt!"}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Interne Gäste
                          </TableCell>
                          <TableCell align="right">
                            {internalGuests || 1}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Externe Gäste
                          </TableCell>
                          <TableCell align="right">
                            {externalGuests || 0}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Preis
                          </TableCell>
                          <TableCell align="right">
                            {startDate && endDate
                              ? endDate.diff(startDate, "DAY") +
                                " Nächte × [(" +
                                (internalGuests || 1) +
                                " intern × " +
                                parseFloat(settings.internalPrice) +
                                " €) + (" +
                                (externalGuests || 0) +
                                " extern × " +
                                parseFloat(settings.externalPrice) +
                                " €)] = " +
                                endDate.diff(startDate, "DAY") *
                                  ((internalGuests || 1) *
                                    parseFloat(settings.internalPrice) +
                                    (externalGuests || 0) *
                                      parseFloat(settings.externalPrice)) +
                                " €"
                              : "Fehlt!"}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Kommentar
                          </TableCell>
                          <TableCell align="right">{comment}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div style={{ marginTop: 10 }}>
                  <Grid container justifyContent="center">
                    {sendStatus === "LOADING" ? (
                      <CircularProgress />
                    ) : sendStatus === "ERROR" ? (
                      <Stack spacing={2}>
                        <div>Verbindungsfehler.</div>
                        <Button variant="contained" onClick={send}>
                          Erneut versuchen
                        </Button>
                      </Stack>
                    ) : (
                      <Stack spacing={2}>
                        <div>Erfolgreich abgesendet.</div>
                        <Button variant="contained" component={Link} to="/">
                          Zur Übersicht
                        </Button>
                      </Stack>
                    )}
                  </Grid>
                </div>
              )}

              {activeStep < steps.length ? (
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Zurück
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  {activeStep !== steps.length - 1 || (startDate && endDate) ? (
                    <Button
                      onClick={
                        activeStep === steps.length - 1 ? send : handleNext
                      }
                      color="secondary"
                      variant="contained"
                    >
                      {activeStep === steps.length - 1 ? "Absenden" : "Weiter"}
                    </Button>
                  ) : null}
                </Box>
              ) : null}
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
