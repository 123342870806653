import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CottageIcon from '@mui/icons-material/Cottage';
import ListItemIcon from '@mui/material/ListItemIcon';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from "react-router-dom";
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const mainListItems = (admin) => (
  <React.Fragment>
    {admin ? <ListItemButton component={Link} to="/all">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Buchungsübersicht" />
    </ListItemButton> : null }
    <ListItemButton component={Link} to="/my">
      <ListItemIcon>
        <CottageIcon />
      </ListItemIcon>
      <ListItemText primary="Meine Buchungen" />
    </ListItemButton>
    <ListItemButton  component={Link} to="/new">
      <ListItemIcon>
        <AddCircleIcon />
      </ListItemIcon>
      <ListItemText primary="Neue Buchung" />
    </ListItemButton>
    <ListItemButton component={Link} to="/calendar">
      <ListItemIcon>
        <CalendarIcon />
      </ListItemIcon>
      <ListItemText primary="Kalender" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (admin) => (
  <React.Fragment>
    <ListItemButton component={Link} to="/myuser">
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText primary="Mein Benutzer" />
    </ListItemButton>
    {admin ? <ListItemButton component={Link} to="/users">
      <ListItemIcon>
        <PeopleAltIcon />
      </ListItemIcon>
      <ListItemText primary="Benutzerverwaltung" />
    </ListItemButton> : null }
    {admin ? <ListItemButton component={Link} to="/settings">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Einstellungen" />
    </ListItemButton> : null }
  </React.Fragment>
);