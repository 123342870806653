import * as React from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { Link, useParams, Navigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import Paper from "@mui/material/Paper";
import huette from "../images/huette.jpg";

export default function SignIn({ onSuccess }) {
  const [status, setStatus] = React.useState("INITIAL");
  const { token } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus("LOADING");
    const data = new FormData(event.currentTarget);
    try {
      const newpw = data.get("newpassword");
      const checkpw = data.get("checkpassword");

      if (checkpw !== newpw){
        setStatus("NOMATCH");
        return;
      }

      const resp = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/bpResetPassword",
        {
          token,
          password: newpw,
        }
      );
      
      setStatus("SUCCESS");
    } catch (err) {
      // Handle Error Here
      setStatus("ERROR");
    }
  };

  return (
    <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Neues Passwort
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            {status === "ERROR" ? (
              <Alert severity="error">Verbindungsfehler.</Alert>
            ) : null}
            {status === "NOMATCH" ? (
              <Alert severity="error">Passwörter stimmen nicht überein.</Alert>
            ) : null}
            {status === "SUCCESS" ? (
              <Navigate
              replace to="/login" state={"RESET"} />
            ) : null}
            <TextField
              margin="normal"
              required
              fullWidth
              name="newpassword"
              label="Neues Passwort"
              type="password"
              id="newpassword"
              autoComplete="new-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="checkpassword"
              label="Neues Passwort wiederholen"
              type="password"
              id="checkpassword"
              autoComplete="new-password"
            />

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={status === "LOADING"}
              sx={{ mt: 3, mb: 2 }}
            >
              Absenden
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link
                  to="/login"
                  variant="body2"
                >
                  zurück
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
  );
}
