import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import History from "./History";
import { Link } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import Stack from "@mui/material/Stack";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import React from "react";

export default function AllBookingsRow({ row, uploadState, commentModal, settings, deleteOrder }) {
  const [open,setOpen]=React.useState(false);
  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 }, '& > *': { borderBottom: 'unset' } }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.state === "NEW" ? (
            <AccessTimeIcon title="Warten auf Bestätigung der Buchung." />
          ) : row.state === "EDITED" ? (
            <AutorenewIcon title="Warten auf Bestätigung der Änderung." />
          ) : row.state === "DENIED" ? (
            <DoDisturbIcon title="Buchung abgelehnt." />
          ) : (
            <CheckIcon title="Akzeptiert." />
          )}
        </TableCell>
        <TableCell>{row.user}</TableCell>
        <TableCell>{moment(row.start).format("dd., DD. MMM YYYY")}</TableCell>
        <TableCell>{moment(row.end).format("dd., DD. MMM YYYY")}</TableCell>
        <TableCell align="right">{row.internalGuests}</TableCell>
        <TableCell align="right">{row.externalGuests}</TableCell>
        <TableCell align="right">{moment(row.end).diff(moment(row.start), "DAY") *
                                  ((row.internalGuests || 1) *
                                    parseFloat(settings.internalPrice) +
                                    (row.externalGuests || 0) *
                                      parseFloat(settings.externalPrice)) +
                                " €"}</TableCell>
        <TableCell>
          <Stack direction="row" spacing={1}>
            <React.Fragment>
              <Button
                variant="contained"
                onClick={() => uploadState(row.id, "ACCEPTED", "")}
              >
                <CheckIcon />
              </Button>{" "}
            </React.Fragment>

            <Button
              color="secondary"
              variant="contained"
              onClick={() => uploadState(row.id, "DENIED", "")}
            >
              <DoDisturbIcon />
            </Button>

            <Button
              color="black"
              variant="contained"
              onClick={() => commentModal(row.id, true, true)}
            >
              <ChatBubbleIcon />
            </Button>
            <Button
                variant="contained"
                component={Link}
                to={"/edit/" + row.id}
                state={{ row }}
              >
                <EditIcon />
              </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => deleteOrder(row.id)}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <History open={open} history={row.history} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
