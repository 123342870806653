import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import History from "./History";
import { Link } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import Stack from "@mui/material/Stack";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import React from "react";

export default function AllUsersRow({ row, settings, deleteUser }) {
  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 }, '& > *': { borderBottom: 'unset' } }}
      >
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.email}</TableCell>
        
        <TableCell>{row.admin ? <CheckCircleIcon/> : <HighlightOffIcon />}</TableCell>
        <TableCell>
          <Stack direction="row" spacing={1}>
            <Button
                variant="contained"
                component={Link}
                to={"/editUser/" + row.id}
                state={{ row }}
              >
                <EditIcon />
              </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => deleteUser(row.id)}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      
    </React.Fragment>
  );
}
